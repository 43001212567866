@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;700;900&display=swap');

:root {
    --light: #dfefd8;
    --mint: #a9d6ba;
    --mint-translucent: #a9d6ba88;
    --mint-transparent: #C4E0CD;
    --green: #454F41;
    --dark: #454F41;
}

body:has(.Dashboard, .Chat) {
    background-color: var(--background);
}

.RegisteredUser {
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    font-family: 'Inconsolata', sans-serif;
}

.Dashboard {
    margin: 0 auto;
    height: min-content;
    width: min-content;
    box-sizing: border-box;
}

:root:has(.light) {
    --background: #CEDEC7;
    --text: #3A4D41;
    --text-transparent: #3a4d41c1;
}

:root:has(.dark) {
    --background: #3A4D41;
    --text: #CDDEC6;
    --text-transparent: #cedec7b0;
}

.Dashboard * {
    color: var(--text);
}

.Dashboard h2 {
    margin: 60px 10px 20px;
    font-size: 2rem;
    width: auto;
}

.bento-row {
    display: flex;
    gap: 40px;
    justify-content: center;
    height: 140px;
    align-items: center;
}

.user-info, .user-description {
    height: 100%;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    text-align: left;
}

.light .user-info, .light .user-description {
    box-shadow: 5px 5px 13px 0px rgba(185, 200, 179, 0.90), -5px -5px 10px 0px rgba(227, 244, 219, 0.90), 5px -5px 10px 0px rgba(185, 200, 179, 0.20), -5px 5px 10px 0px rgba(185, 200, 179, 0.20), -1px -1px 2px 0px rgba(185, 200, 179, 0.50) inset, 1px 1px 2px 0px rgba(227, 244, 219, 0.30) inset;
}

.dark .user-info, .dark .user-description {
    box-shadow: 20px 20px 50px 0px rgba(49, 65, 55, 0.90), -20px -20px 40px 0px rgba(67, 89, 75, 0.90), 20px -20px 40px 0px rgba(49, 65, 55, 0.20), -20px 20px 40px 0px rgba(49, 65, 55, 0.20), -1px -1px 2px 0px rgba(49, 65, 55, 0.50) inset, 1px 1px 2px 0px rgba(67, 89, 75, 0.30) inset;
}

.user-info *, .user-description * {
    margin: 0;
}

.user-info {
    width: 230px;
}

.user-info .name {
    font-size: 25px;
    font-weight: 600;
}

.user-info .contact {
    width: 150px;
    color: var(--text-transparent);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    white-space: nowrap;
}

.class-of {
    font-weight: 400;
    font-size: 1.3rem;
}

.user-description {
    width: 530px;
}

.user-description .bio {
    font-size: 1.2rem;
}

.tags {
    display: flex;
    margin-top: 20px;
    gap: 20px;
}

.tag {
    border-radius: 5px;
    padding: 5px 10px;
}

.light .tag {
    box-shadow: 5px 5px 13px 0px rgba(186, 203, 178, 0.90), -5px -5px 10px 0px rgba(228, 248, 218, 0.90), 5px -5px 10px 0px rgba(186, 203, 178, 0.20), -5px 5px 10px 0px rgba(186, 203, 178, 0.20), -1px -1px 2px 0px rgba(186, 203, 178, 0.50) inset, 1px 1px 2px 0px rgba(228, 248, 218, 0.30) inset;
    background: linear-gradient(135deg, #C5D6BC 0%, #D9ECD0 100%);
}

.dark .tag {
    box-shadow: 5px 5px 13px 0px rgba(52, 69, 59, 0.90), -5px -5px 10px 0px rgba(64, 85, 72, 0.90), 5px -5px 10px 0px rgba(52, 69, 59, 0.20), -5px 5px 10px 0px rgba(52, 69, 59, 0.20), -1px -1px 2px 0px rgba(52, 69, 59, 0.50) inset, 1px 1px 2px 0px rgba(64, 85, 72, 0.30) inset;
    background: linear-gradient(135deg, #37493E 0%, #3D5144 100%);
}