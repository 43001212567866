.coming-soon {
    font-size: 3rem;
    text-align: center;
    margin-top: 100px;
}

#chat-box {
    position: absolute;
    width: 80%;
    height: 50px;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 40px;
    box-sizing: border-box;
    background-color: var(--text);
    font-family: 'DM Sans', sans-serif;
    font-size: 1.3rem;
    outline: none;
    border: none;
    border-radius: 5px;
}

#chat-box, #chat-box::placeholder {
    color: var(--background);
}

.sent, .received {
    margin: 2px 0;
}

:root:has(.light) {
    --sent: #284830;
    --received: #282578;
}

:root:has(.dark) {
    --sent: #e0f7e6;
    --received: #e2e1f8;
}

.sent {
    color: var(--sent);
}

.received {
    color: var(--received);
}

.message-container {
    margin: 40px 80px;
    height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

@media (max-width: 700px) {
    .message-container {
        margin: 40px;
    }

    #chat-box {
        font-size: 1.15rem;
    }
}

@media (max-width: 590px) {
    .message-container {
        margin: 40px 30px;
    }
}

@media (max-width: 350px) {
    .message-container {
        margin: 30px 20px;
    }

    #chat-box {
        font-size: 0.8rem;
    }
}