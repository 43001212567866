html, body, #root {
    height: 100%;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #000;
    color: #fff;
}

.loading {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-family: 'DM Sans', sans-serif;
}