@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,500&family=DM+Serif+Display&family=Oswald&family=Unna:wght@700&display=swap');

.Landing {
    position: relative;
    overflow: hidden;
    background-image: radial-gradient(#ffffff38 1px, transparent 0);
    background-size: 60px 60px;
}

.Landing h1, .Landing h2 {
    text-align: center;
}

h1 {
    font-family: 'Copperplate', serif;
    font-size: 17.5rem;
    font-weight: 400;
    margin: 100px auto 0;
    line-height: 1;
    letter-spacing: -20px;
}

.hero h2 {
    font-family: 'DM Serif Display', serif;
    font-size: 2.5rem;
    margin: 50px auto 60px;
    width: 500px;
    line-height: 1.7;
}

@keyframes background-pan {
    from {background-position-x: 0;}
    to {background-position-x: -200%;}
}

.gradient {
    background: linear-gradient(
        to right, 
        #16788e, 
        #1aab98, 
        #25ab83, 
        #007780
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200%;
    animation: background-pan 2s linear infinite;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
}

.gradient:nth-of-type(2) {
    transition-delay: -1s;
}

.hero button {
    position: relative;
    border-radius: 10px;
    background: #FFF;
    color: #000;
    border: 2px solid #000;
    font-family: "DM Serif Display", serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01956rem;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 auto;
    transition-duration: .2s;
}

button:hover {
    transform: scale(1.05);
    border: 2px solid #fff;
    background: #000;
    color: #fff;
}

.blob {
    position: absolute;
    pointer-events: none;
}

.top, .right {
    z-index: -1;
    right: -1rem;
}

.left {
    --right-displacement: 880px;
    width: 50rem;
    top: 5rem;
    right: calc((100vw - var(--right-displacement))/2 + 800px);
}

.top {
    width: 60rem;
    top: -3.5rem;
}

.right {
    width: 40rem;
    top: 17rem;
    filter: blur(15px);
}

#carousel {
    --initial-rotation: 125deg;
    --translationZ: -2000px;
    --translationY: -100px;
    --scroll-multiplier: 0.05deg;
    position: relative;
    display: flex;
    height: 680px;
    width: 800px;
    transform: perspective(1000px) scale(1.5) rotateY(calc(var(--initial-rotation) - var(--scroll-position) * var(--scroll-multiplier))) translateY(calc(var(--scroll-position) * -0.2px));
    margin: 350px auto -134px;
    transform-style: preserve-3d;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
    user-select: none;
}

.carousel-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(calc((var(--index)) * -25deg)) translateZ(var(--translationZ)) rotateZ(0deg) translateY(calc(var(--translationY)));
    border-radius: 15px;
    will-change: transform;
}

#testimonial {
    width: 40rem;
    margin: 0 auto;
}

#testimonial * {
    width: 100% !important;
}

#testimonial :is(h2, h3) {
    text-align: left;
    line-height: 1;
    margin: 0;
}

#testimonial h2, .block h2 {
    font-size: 4.4rem;
}

.description {
    color: #FFFFFFB2;
    font-size: 2rem;
}

.team-images .description {
    font-size: 1rem;
    line-height: 0.5;
}

.Landing p {
    margin-top: 20px;
    text-align: justify;
    font-family: 'Oswald', sans-serif;
    font-size: 1.8rem;
    line-height: 1.63;
    letter-spacing: 1px;
}

.block {
    width: 1000px;
    margin: 200px auto;
}

.block h2 {
    margin: 100px 0 0;
    line-height: 1;
}

.team-images img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.team-images p {
    margin: 0 0 0 5px;
}

.team-images {
    display: flex;
    margin: 50px 0;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 1040px) {
    h1 {
        font-size: 16rem;
    }

    #carousel {
        height: 640px;
        width: 760px;
        --translationZ: -1900px;
    }
}

@media (max-width: 940px) {
    h1 {
        font-size: 14rem;
    }

    .left {
        top: 8rem;
        --right-displacement: 1100px;
        width: 40rem;
    }

    .top {
        width: 50rem;
        right: -10rem;
    }

    .right {
        width: 30rem;
    }

    .hero h2 {
        font-size: 2.3rem;
    }

    #carousel {
        --scroll-multiplier: 0.07deg;
        --initial-rotation: 135deg;
        margin-top: 400px;
    }
}

@media (max-width: 800px) {
    h1 {
        font-size: 12rem;
    }

    .left {
        --right-displacement: 1150px;
        width: 30rem;
    }

    .right {
        width: 28rem;
    }

    #testimonial, .block {
        width: 35rem;
    }

    #testimonial h2, .block h2 {
        font-size: 4rem;
    }
}

@media (max-width: 670px) {
    h1 {
        font-size: 10rem;
    }

    .hero h2 {
        font-size: 2rem;
        width: 400px;
    }

    .left {
        width: 30rem;
        top: 4rem;
        --right-displacement: 1200px;
    }

    .top {
        width: 40rem;
        right: -5rem;
        top: -2rem;
    }

    .right {
        right: -8rem;
        top: 15rem;
    }

    .hero button {
        font-size: 1.8rem;
    }

    #carousel {
        height: 600px;
        width: 720px;
        --translationZ: -1800px;
        --translationY: -90px;
        --scroll-multiplier: 0.08deg;
        --initial-rotation: 140deg;
    }

    .testimonial {
        margin-top: 100px;
    }

    #testimonial, .block {
        width: 30rem;
    }

    .Landing p {
        font-size: 1.5rem;
        line-height: 1.8;
    }
}

@media (max-width: 550px) {
    h1 {
        font-size: 8rem;
        letter-spacing: -10px;
    }

    .hero h2 {
        font-size: 1.8rem;
    }

    .left {
        display: none;
    }

    .top {
        width: 30rem;
        right: 0;
        left: -5rem;
        top: 0;
        transform: rotateY(180deg);
    }

    .right {
        top: 12rem;
        right: -13rem;
    }

    .hero button {
        font-size: 1.5rem;
        padding: 10px 25px;
    }

    #carousel {
        height: 500px;
        width: 620px;
        --translationZ: -1600px;
        --scroll-multiplier: 0.095deg;
    }

    #testimonial, .block {
        width: 25rem;
    }

    #testimonial h2, .block h2 {
        font-size: 3.2rem;
    }

    #testimonial h3 {
        font-size: 2rem;
    }
}

@media (max-width: 460px) {
    h1 {
        font-size: 6rem;
    }

    .hero h2 {
        font-size: 1.4rem;
        width: 300px;
    }

    .top {
        width: 22rem;
    }

    .right {
        width: 20rem;
        right: -8rem;
    }

    .hero button {
        font-size: 1.3rem;
    }

    #carousel {
        height: 400px;
        width: 520px;
        --translationZ: -1300px;
        --translationY: -65px;
    }

    #testimonial, .block {
        width: 20rem;
    }
}