.Nav nav {
    position: relative;
    display: flex;
    height: 120px;
    border-radius: 15px;
    padding: 20px 40px;
    box-sizing: border-box;
    align-items: center;
}

.light nav {
    box-shadow: 5px 5px 13px 0px rgba(185, 200, 179, 0.90), -5px -5px 10px 0px rgba(227, 244, 219, 0.90), 5px -5px 10px 0px rgba(185, 200, 179, 0.20), -5px 5px 10px 0px rgba(185, 200, 179, 0.20), -1px -1px 2px 0px rgba(185, 200, 179, 0.50) inset, 1px 1px 2px 0px rgba(227, 244, 219, 0.30) inset;
}

.dark nav {
    box-shadow: 5px 5px 13px 0px rgba(52, 69, 59, 0.90), -5px -5px 10px 0px rgba(64, 85, 72, 0.90), 5px -5px 10px 0px rgba(52, 69, 59, 0.20), -5px 5px 10px 0px rgba(52, 69, 59, 0.20), -1px -1px 2px 0px rgba(52, 69, 59, 0.50) inset, 1px 1px 2px 0px rgba(64, 85, 72, 0.30) inset;
}

.Nav nav .logo {
    height: 80px;
    border-radius: 50%;
}

.light nav .logo {
    background: linear-gradient(145deg, #b9c8b3, #dceed5);
    box-shadow: 8px 8px 16px #afbda9,
        -8px -8px 16px #edffe5;
}

.dark nav .logo {
    background: linear-gradient(145deg, #34453b, #3e5246);
    box-shadow: 8px 8px 16px #314137,
        -8px -8px 16px #43594b;
}

.Nav nav a {
    text-decoration: none;
    color: var(--text-transparent);
    font-size: 2rem;
    margin-left: 100px;
}

.Nav nav a:not(.active) {
    transition-duration: .2s;
}

.Nav nav a:hover {
    color: var(--text);
}

.Nav nav a.active {
    color: var(--text);
    border-radius: 5px;
    padding: 5px 10px;
}

.light nav a.active {
    box-shadow: 5px 5px 13px 0px rgba(186, 203, 178, 0.90), -5px -5px 10px 0px rgba(228, 248, 218, 0.90), 5px -5px 10px 0px rgba(186, 203, 178, 0.20), -5px 5px 10px 0px rgba(186, 203, 178, 0.20), -1px -1px 2px 0px rgba(186, 203, 178, 0.50) inset, 1px 1px 2px 0px rgba(228, 248, 218, 0.30) inset;
    background: linear-gradient(135deg, #C5D6BC 0%, #D9ECD0 100%);
}

.dark nav a.active {
    box-shadow: 10px 10px 25px 0px rgba(49, 65, 55, 0.90), -10px -10px 20px 0px rgba(67, 89, 75, 0.90), 10px -10px 20px 0px rgba(49, 65, 55, 0.20), -10px 10px 20px 0px rgba(49, 65, 55, 0.20), -1px -1px 2px 0px rgba(49, 65, 55, 0.50) inset, 1px 1px 2px 0px rgba(67, 89, 75, 0.30) inset;
    background: linear-gradient(135deg, #37493E 0%, #3D5144 100%);
}

.user {
    display: flex;
    margin-left: auto;
    align-items: center;
    color: var(--text-transparent);
}

.user img {
    height: 70px;
    opacity: 0.7;
    transition-duration: .2s;
}

.user img:hover {
    opacity: 1;
}

.dark .user img {
    filter: invert(1) hue-rotate(180deg);
}

.dropdown {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    background-color: var(--text);
    box-sizing: border-box;
    padding: 5px 2px;
    gap: 2px;
    top: 100%;
    right: 62.5px;
    width: 150px;
    height: 120px;
    border-radius: 5px 0 5px 5px;
    transition: .2s ease;
    transform: translateY(-10px);
    visibility: hidden;
}

.dropdown.show {
    opacity: 1;
    transform: none;
    visibility: visible;
}

.dropdown::before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid var(--text);
}

.dropdown a {
    display: block;
    color: var(--background) !important;
    background-color: var(--text);
    font-size: 1rem !important;
    margin: 0 !important;
    padding: 10px 5px;
    transition-duration: .2s;
    border-left: 2px solid var(--text);
    border-right: 2px solid var(--text);
}

.dropdown a:hover {
    opacity: 1;
    color: var(--text) !important;
    background-color: var(--background);
}

@media (max-width: 770px) {
    .Nav nav a {
        font-size: 1.5rem;
        margin-left: 50px;
    }

    .Nav nav .logo {
        height: 60px;
    }

    .user img {
        height: 50px
    }
}

@media (max-width: 540px) {
    .Nav nav {
        padding: 20px;
    }

    .Nav nav a {
        margin-left: 30px;
    }
}

@media (max-width: 440px) {
    nav .dropdown {
        right: 12px;
        top: 65px;
    }

    .Nav nav {
        flex-direction: column;
        align-items: start;
        height: 150px;
    }
    
    .Nav nav :is(.user, a) {
        margin-left: 0;
    }

    nav .user {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .Nav nav .logo {
        height: 50px;
    }
    
    .nav-links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;
    }
}