body:has(.Modal) {
    overflow: hidden;
}

.ReactModal__Overlay {
    background-color: #000000b3 !important;
    backdrop-filter: blur(8px);
}

.Modal {
    position: absolute;
    width: 700px;
    max-height: 700px;
    inset: 0;
    margin: auto;
    outline: 1px solid var(--dark);
    font-family: 'Inconsolata', sans-serif;
    text-align: center;
    border-radius: 15px;
    border: .5px solid #ffffff7a;
    overflow: hidden;
    background-color: #00000081;
    backdrop-filter: blur(1px);
    color: #fff;
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.7;
    transition-duration: .2s;
}

.close-modal:hover {
    opacity: 1;
}

.Modal h2 {
    margin: 30px 0 40px;
}

.registration-message p, .Reset p {
    line-height: 2;
    width: 80%;
    margin: 0 auto 20px;
}

.Modal form label {
    margin-left: 90px;
}

.Modal form input[name="classOf"] {
    width: 150px;
    height: 50px;
}

.Modal form input, .Modal form label  {
    display: block;
    text-align: left;
    font-size: 1.2rem;
}

.Modal form input, .error, .tag-container {
    width: calc(100% - 160px);
} 

.Modal .tag-container {
    display: flex;
    margin: 2px 0 0 80px;
    gap: 10px;
}

.tag-container input {
    width: 50px;
    margin: 0 !important;
}

.Modal form input {
    margin-left: 80px;
    background-color: transparent;
    border: 1px solid #ffffff88;
    border-radius: 5px;
    margin-top: 2px;
    padding: 5px 10px;
    box-sizing: border-box;
    outline: none;
    font-family: 'Inconsolata', sans-serif;
    caret-color: #fff;
    background-color: #000000b3;
}

.Modal form input {
    height: 40px;
    color: #fff;
}

.error {
    color: #c75252;
    font-size: 1rem;
    height: 15px;
    margin: 5px 0 10px 90px;
    text-align: left;
}

.password-container {
    display: flex;
    position: relative;
}

.password-visibility {
    opacity: 0.7;
    position: absolute;
    right: 90px;
    top: 10px;
    transition-duration: .2s;
}

.password-visibility:hover {
    opacity: 1;
}

.modal-bottom {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.Modal button {
    background-color: #fff;
    font-size: 1.2rem;
    font-family: 'Inconsolata', sans-serif;
    padding: 15px;
    border-radius: 15px;
    color: #000;
    border: 2px solid #000;
    width: 300px;
    margin-bottom: 10px;
    transition-duration: .2s;
}

.Modal button:hover {
    transform: scale(1.05);
}

.edge-case {
    font-size: 1rem;
    margin: 5px 0;
}

.edge-case a {
    color: #ffffffb3;
    text-decoration: none;
    font-weight: 600;
}

.edge-case a:hover {
    text-decoration: underline;
}

@media (max-width: 800px) {
    .Modal {
        width: 600px;
    }
}

@media (max-width: 650px) {
    .Modal {
        width: 500px;
    }
}

@media (max-width: 530px) {
    .Modal {
        width: 450px;
    }

    .Modal form label, .error {
        margin-left: 70px;
        width: calc(100% - 140px);
    }

    .Modal form input, .tag-container {
        margin-left: 60px;
        width: calc(100% - 120px)
    }

    .password-visibility {
        right: 70px;
    }

    .Modal button {
        width: 250px;
    }
}

@media (max-width: 470px) {
    .Modal {
        width: 400px;
        max-height: 500px;
    }

    .Modal h2 {
        font-size: 2rem;
        margin: 20px 0 30px;
    }
    
    .Modal form input, .Modal form label {
        font-size: 1rem;
    }

    .error {
        font-size: 0.8rem;
        height: 15px;
        margin-bottom: 15px;
    }

    .Modal button {
        padding: 8px;
        font-size: 1rem;
        width: 200px;
    }

    .modal-bottom {
        bottom: 10px;
    }
}

@media (max-width: 420px) {
    .Modal {
        width: 320px;
    }

    .Modal h2 {
        font-size: 1.8rem;
    }

    .Modal form label, .error {
        margin-left: 40px;
        width: calc(100% - 80px);
    }

    .Modal form input, .tag-container {
        margin-left: 30px;
        width: calc(100% - 60px);
    }

    .password-visibility {
        right: 40px;
    }
}